<template>
  <v-text-field
    :label="label"
    :value="value"
    @change="change"
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-menu v-model="menuShow" :close-on-content-click="false" offset-y top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon tile v-bind="attrs" v-on="on" @click="getResources">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <v-card width="480">
          <v-card-title class="py-1">
            <span>选择图片</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="menuShow = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-text-field
            v-model="filter"
            label="搜索"
            hide-details
            dense
            outlined
            prepend-inner-icon="mdi-magnify"
            class="px-4 pt-4"
          ></v-text-field>
          <v-container fluid style="min-height: 245px">
            <v-row>
              <v-col
                v-for="resource in pageItems"
                :key="resource.uuid"
                cols="3"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      @click="select(resource)"
                      v-bind="attrs"
                      v-on="on"
                      aspect-ratio="1"
                      :src="`data:image/png;base64,${resource.thumbnail}`"
                      class="elevation-4"
                    >
                      <div v-if="!resource.thumbnail" class="text-center">
                        {{ resource.name }}
                      </div>
                    </v-img>
                  </template>
                  <span v-text="resource.name"></span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-pagination
            v-model="pageIndex"
            :length="pageCount"
            :total-visible="5"
          ></v-pagination>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import proto from "../../utils/proto";
import client from "../../utils/client";

export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      resources: [],
      filter: "",
      pageIndex: 1,
      pageSize: 12,
      menuShow: false,
    };
  },
  watch: {
    filter: function () {
      this.pageIndex = 1;
    },
  },
  computed: {
    items() {
      if (this.filter) {
        return this.resources.filter(this.matches);
      } else {
        return this.resources;
      }
    },
    pageItems() {
      let start = (this.pageIndex - 1) * this.pageSize;
      if (start > this.items.length) start = this.items.length;
      let end = start + this.pageSize;
      if (end > this.items.length) end = this.items.length;
      return this.items.slice(start, end);
    },
    pageCount() {
      return Math.ceil(this.items.length / this.pageSize);
    },
  },
  methods: {
    async getResources() {
      client.$emit(
        "startBusy",
        "ResourceEditor.getResources",
        "正在获取页面资源,请稍候..."
      );
      await proto.sleep(100);
      let result = [];
      try {
        let res = await client.send(proto.MESSAGE_TYPE.resourceMessage, {
          resType: 2,
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
        });
        if (res.resources) {
          res.resources.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          result = res.resources;
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      this.resources = result;
      client.$emit("endBusy", "ResourceEditor.getResources");
    },
    matches(item) {
      return item.name.indexOf(this.filter) != -1;
    },
    select(resource) {
      this.$emit("change", resource.uuid);
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
